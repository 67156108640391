<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="handleClose"
      class="aboutsNews"
    >
      <el-form ref="formDetail" :model="formDetail" label-width="120px" :rules="rules">
        <!-- <div v-if="infoStatus" class="explain">
          <div class="title">
            <div>注意：</div>
            <div @click="closeInfo">关闭</div>
          </div>
          <div>1.预约类型为“线下授课”需要选择“预约时间”；</div>
          <div>2.预约类型为“专题讲座”、“定制课程”、“其他”无需选择预约时间</div>
        </div> -->
        <el-form-item label="预约类型" prop="reserveType">
          <el-select
            v-model="formDetail.reserveType"
            placeholder="请选择预约类型"
            class="input-width"
            clearable
          >
            <el-option label="线下授课" :value="1"></el-option>
            <el-option label="专题讲座" :value="2"></el-option>
            <el-option label="定制课程" :value="3"></el-option>
            <el-option label="其他" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预约时间:" prop="reserveTime">
          <el-date-picker
            v-model="formDetail.reserveTime"
            type="datetime"
            placeholder="选择日期"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            class="input-width"
            :picker-options="{
              disabledDate: time => {
                return time.getTime() < Date.now() - 1 * 3600 * 1000
              },
            }"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="培训人数：" prop="studentCount">
          <el-input
            v-model="formDetail.studentCount"
            placeholder="请输入培训人数"
            oninput="value=value.replace(/[^\d]/g,'')"
            class="input-width"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item v-if="data" label="更换老师：" prop="teacherId">
          <el-select
            v-model="formDetail.teacherId"
            placeholder="请选择更换老师"
            filterable
            class="input-width"
            clearable
          >
            <el-option
              v-for="item in selectList"
              :key="item.key"
              :value="Number(item.key)"
              :label="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预估费用" prop="costBudget">
          <el-select
            v-model="formDetail.costBudget"
            placeholder="请选择预估费用"
            class="input-width"
            clearable
          >
            <el-option label="￥5000.00-￥10000.00" :value="1"></el-option>
            <el-option label="￥10000.00-￥15000.00" :value="2"></el-option>
            <el-option label="￥15000.00-￥20000.00" :value="3"></el-option>
            <el-option label="￥20000.00-￥30000.00" :value="4"></el-option>
            <el-option label="￥30000.00-￥40000.00" :value="5"></el-option>
            <el-option label="￥50000.00-￥100000.00" :value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人：" prop="contactName">
          <el-input
            v-model="formDetail.contactName"
            placeholder="请输入联系人"
            class="input-width"
            clearable
            maxlength="12"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="contactPhone">
          <el-input
            v-model="formDetail.contactPhone"
            placeholder="请输入手机号码"
            oninput="value=value.replace(/[^\d]/g,'')"
            class="input-width"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="附件：">
          <el-upload
            class="upload-demo"
            action=""
            :on-remove="handleRemove"
            :http-request="uploadRequest"
            multiple
            :limit="3"
            accept=".jpg,.jpeg,.png,.gif,.svg,.JPG,.JPEG,.xlsx,.xls,.doc"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">请选择附件</el-button>
            <span slot="tip" class="el-upload__tip">(支持Word、Excel、图片等文件)</span>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="loading" @click="submitDialogNews('formDetail')">{{
          btnText
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCookie } from '@/utils/cookies'
import { validateInputMobile } from '@/utils/validate'
import { getFileUpload, getAddAboutNews, getMyEditAbout, getTeacherSelectList } from '@/api/teacher'
import to from 'await-to'
export default {
  props: {
    dialogTitle: {
      type: String,
      default: '',
    },
    infoStatus: {
      type: Boolean,
      default: false,
    },
    teacherId: {
      type: Number,
      default: null,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    btnText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      selectList: [],
      btnDetail: '',
      formDetail: {
        reserveType: '',
        reserveTime: '',
        studentCount: '',
        costBudget: '',
        contactName: getCookie('userName'),
        contactPhone: getCookie('phone'),
        annexList: [],
        teacherId: '',
      },
      fileList: [],
      paramsFileList: [],
      rules: {
        reserveType: [{ required: true, message: '请选择预约类型', trigger: 'change' }],
        studentCount: [
          { required: true, message: '请输入预约人数', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value) {
                const boolean = new RegExp('^[1-9][0-9]*$').test(value)
                if (!boolean) {
                  this.formDetail.studentCount = ''
                }
              }
              callback()
            },
          },
        ],
        costBudget: [{ required: true, message: '请选择预估费用', trigger: 'change' }],
        contactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        reserveTime: [{ required: true, message: '请选择预约时间', trigger: 'change' }],
        teacherId: [{ required: true, message: '请选择讲师', trigger: 'change' }],
        contactPhone: [{ required: true, validator: validateInputMobile, trigger: 'blur' }],
      },
    }
  },
  watch: {
    data(val) {
      if (val) {
        val.annexList.forEach(item => {
          if (item.annexUrl == null || item.annexName == null) {
            this.fileList = []
          } else {
            this.fileList.push({ url: item.annexUrl, name: item.annexName })
          }
        })
        this.formDetail = val
        this.getTeacherSelectListData()
      }
    },
  },
  methods: {
    filterFileList() {
      let arr = []
      this.fileList.map(v => {
        arr.push({ annexUrl: v.url, annexName: v.name })
      })
      return arr
    },
    async getTeacherSelectListData() {
      const [res, err] = await to(getTeacherSelectList())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.selectList = res.data
    },
    async submitDialogNews(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.formDetail.annexList = this.filterFileList()
          if (this.teacherId) {
            this.loading = true
            this.formDetail.teacherId = this.teacherId
            const [, err] = await to(getAddAboutNews(this.formDetail))
            this.loading = false
            if (err) return this.$message({ type: 'error', message: err.msg })
            this.$message({ type: 'success', message: '预约成功' })
            this.$emit('success')
          } else {
            this.loading = true
            const [, err] = await to(getMyEditAbout(this.formDetail))
            this.loading = false
            if (err) return this.$message({ type: 'error', message: err.msg })
            this.$message({ type: 'success', message: '编辑成功' })
            this.$emit('success')
          }
          this.$refs[formName].resetFields()
          this.handleClose()
        }
      })
    },
    async uploadRequest(file) {
      const params = new FormData() //创建FormData
      params.append('fileTypeCode', 'F001')
      params.append('file', file.file)
      params.append('fileName', file.file.name)
      let config = {
        onUploadProgress: progressEvent => {
          let num = ((progressEvent.loaded / progressEvent.total) * 100) | 0 //百分比
          file.onProgress({ percent: num }) //进度条
        },
      }
      const [res, err] = await to(getFileUpload(params, config))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '上传成功' })
      this.fileList.push({ url: res.data.fileUrl, name: res.data.fileName })
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handleExceed() {
      this.$message.warning(`当前限制选择 3 个文件`)
    },
    closeInfo() {
      this.$emit('closeInfo', this.infoStatus)
    },
    handleClose() {
      if (this.teacherId) {
        this.$emit('handleClose', this.dialogVisible)
      } else {
        this.$emit('handleClose', this.dialogVisible)
        this.fileList = []
      }
    },
  },
}
</script>

<style scoped lang="scss">
.aboutsNews {
  ::v-deep .el-dialog__footer {
    text-align: center;
  }
  .el-upload__tip {
    margin-left: 10px;
  }
  .explain {
    font-size: 14px;
    padding: 20px 30px;
    box-sizing: border-box;
    background-color: #ff7b33;
    margin-bottom: 20px;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  .input-width {
    width: 235px !important;
  }
}
</style>
